import '../css/app.css';
import './bootstrap';
import cookie from 'jquery.cookie';
import inputmask from "inputmask";
import metisMenu from 'metismenu';
import slick from 'slick-carousel';
import Clipboard from 'clipboard';
import Swiper from 'swiper/bundle';
import {Fancybox} from "@fancyapps/ui";
import Alpine from 'alpinejs';

window.cookie = cookie;
window.inputmask = inputmask;
window.metisMenu = metisMenu;
window.slick = slick;
window.ClipboardJS = Clipboard;
window.Swiper = Swiper;
window.Fancybox = Fancybox;
window.Alpine = Alpine;
Alpine.start();
